.nav {
    height: 100vh;
    width: 100vw;
}

.mobileNav{
    display: none;
}

.nav a:link, .nav a:visited {
    text-transform: uppercase;
    font-family: "Gingham";
    /* color: #EDEEC0; */
    color: #426A5A;
}

.nav a:hover {
    /* color: #433E0E; */
    color: #F2C57C;
}

.topleftcorner {
    /* border-color: transparent transparent transparent #A7A284; */
    border-color: transparent transparent transparent #DDAE7E;
    border-style: solid;
    border-width: 0px 0px 190px 190px;
    height: 0px;
    width: 0px;
    position: absolute;
    z-index: 1;
}

.toprightcorner {
    /* border-color: transparent #A7A284 transparent transparent; */
    border-color: transparent #DDAE7E transparent transparent;
    border-style: solid;
    border-width: 0px 190px 190px 0px;
    height: 0px;
    width: 0px;
    position: absolute;
    right:0;
    z-index: 1;
}

.bottomleftcorner {
    /* border-color: transparent transparent transparent #A7A284; */
    border-color: transparent transparent transparent #DDAE7E;
    border-style: solid;
    border-width: 190px 0px 0px 190px;
    height: 0px;
    width: 0px;
    position: absolute;
    bottom:0;
    left:0;
    z-index: 1;
}

.bottomrightcorner {
    /* border-color: transparent #A7A284 transparent transparent; */
    border-color: transparent #DDAE7E transparent transparent;
    border-style: solid;
    border-width: 190px 190px 0px 0px;
    height: 0px;
    width: 0px;
    position: absolute;
    bottom:0;
    right: 0;
    z-index: 1;
}

.home {
    position: absolute;
    top: 50px;
    left: 50px;
    transform: rotate(-45deg);
    z-index: 1;
    font-size: 1.4em;
}

.about {
    position: absolute;
    top: 50px;
    right: 50px;
    transform: rotate(45deg);
    z-index: 1;
    font-size: 1.4em;
}

.contact {
    position: absolute;
    bottom: 50px;
    right: 50px;
    transform: rotate(-45deg);
    z-index: 1;
    font-size: 1.4em;
}

.projects {
    position: absolute;
    bottom: 50px;
    left: 50px;
    transform: rotate(45deg);
    z-index: 1;
    font-size: 1.4em;
}

@media screen and (max-width: 1086px){
    .topleftcorner {
        border-width: 0px 0px 150px 150px;
    }
    
    .toprightcorner {
        border-width: 0px 150px 150px 0px;
    }
    
    .bottomleftcorner {
        border-width: 150px 0px 0px 150px;
    }
    
    .bottomrightcorner {
        border-width: 150px 150px 0px 0px;
    }
    
    .home {
        top: 30px;
        left: 30px;
        font-size: 1.2em;
    }
    
    .about {
        top: 30px;
        right: 30px;
        font-size: 1.2em;
    }
    
    .contact {
        bottom: 30px;
        right: 30px;
        font-size: 1.2em;
    }
    
    .projects {
        bottom: 30px;
        left: 30px;
        font-size: 1.2em;
    }
}

@media screen and (max-width: 850px){
    .topleftcorner {
        border-width: 0px 0px 105px 105px;
    }
    
    .toprightcorner {
        border-width: 0px 105px 105px 0px;
    }
    
    .bottomleftcorner {
        border-width: 105px 0px 0px 105px;
    }
    
    .bottomrightcorner {
        border-width: 105px 105px 0px 0px;
    }
    
    .home {
        top: 20px;
        left: 20px;
        font-size: 1em;
    }
    
    .about {
        top: 20px;
        right: 20px;
        font-size: 1em;
    }
    
    .contact {
        bottom: 20px;
        right: 20px;
        font-size: 1em;
    }
    
    .projects {
        bottom: 20px;
        left: 20px;
        font-size: 1em;
    }
}

@media screen and (max-width: 580px){
    .mainNav {
        display: none;
    }

    .mobileNav{
        position: fixed;
        display: block;
        width: 100vw;
        height: 10%;
        /* background-color: #A7A284; */
        background-color: #DDAE7E;
        z-index: 5;
    }
    
    .menu{
        width: 30%;
        text-align: center;
        margin: 0 auto;
        font-size: 60px;
        /* color: #EDEEC0; */
        color: #F2C57C;
    }
    
    .closed {
        height:0px;
        -webkit-transition:height, 0.5s linear;
        -moz-transition: height, 0.5s linear;
        -ms-transition: height, 0.5s linear;
        -o-transition: height, 0.5s linear;
        transition: height, 0.5s linear;
    }
    
    .open {
        display: block;
        position: relative;
        margin-top: -1px;
        width: 100vw;
        height: 165px;
        /* background-color: #433E0E; */
        background-color: #426A5A;
        -webkit-transition:height, 0.5s linear;
        -moz-transition: height, 0.5s linear;
        -ms-transition: height, 0.5s linear;
        -o-transition: height, 0.5s linear;
        transition: height, 0.5s linear;
    }
    
    nav ul {
        list-style-type: none;
        margin: 0;
        padding: 0;
    }

    nav ul li a {
        text-decoration: none;
        color: #F2C57C;
    }

    .nav a:link, .nav a:visited {
        color: #F2C57C;
    }

    .mobileLink {
        display:block;
        position: relative;
        text-align: center;
        padding: 10px;
    }

    .closed .mobileLink {
        display: none;
    }
      
}