.footerLinks {
    margin: 0 auto;
    width: 65%;
    display: flex;
    justify-content: space-around;
    margin-bottom: 15px;
}

.faIcon {
    /* color: #433E0E; */
    color: #426A5A;
    margin-top: 14px;
}

.faIcon:hover {
    /* color: #EDEEC0; */
    color: #F2C57C;
}