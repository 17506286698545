.projects {
    margin: 0 auto;
    width: 85%;
    min-height: 350px;

}

.c {
    min-height: 350px;
    width: 80%;
    will-change: transform, opacity;
}

.title {
    font-size: 1em;
    font-weight: 800;
    /* color: #433E0E; */
    color: #426A5A;
}

.image {
    width: 80%;
    height: 175px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 12px 0 rgba(0, 0, 0, 0.19);
}

.image:hover {
    box-shadow: 0 16px 24px 0 rgba(0, 0, 0, 0.5), 0 18px 32px 0 rgba(0, 0, 0, 0.19);
}

.summary {
    height: 50px;
    font-size: .89em;
    /* color: #433E0E; */
    color: #426A5A;
}

.btn {
    border-radius: 8px;
    margin: 5px;
    padding: 5px;
    /* background-color: #2B4162; */
    background-color: #EF6F6C;
    border: 1px solid #EF6F6C;
    /* box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.18), 0 6px 12px 0 rgba(0, 0, 0, 0.19); */
}

.btn:hover {
    /* background-color: #433E0E; */
    background-color: #426A5A;
    border: 1px solid #426A5A;
}

.btn a {
    text-decoration: none;
    color: #F5F5F5;
}

.btn a:hover {
    color: #F5F5F5;
}

.factList {
    position: absolute;
    top: -350px;
    list-style-type: none;
    margin: 0;
    padding: 0;
}

.factLi {
    font: 200 12px/1.5 'Gingham', Helvetica, sans-serif;
    border-bottom: 1px solid #ccc;
}

.factLi:last-child {
    border: none;
}

.factLi:hover {
    font-size: 20px;
    /* background: #EDEEc0; */
    background: #F2c57C;
}


@media screen and (max-width: 1086px) {
    .title {
        font-size: .91em;
    }
    .image {
        height: 155px;
    }
    .summary {
        font-size: .68em;
        height: 40px;
    }
    .btn {
        height: 23px;
        font-size: .75em;
        margin: 3px;
        padding: 3px;
    }
    .factList {
        top: -296px;
        font-size: .75em;
    }
}

@media screen and (max-width: 850px) {
    .title {
        font-size: .82em;
    }
    .image {
        height: 135px;
    }
    .summary {
        font-size: .43em;
        height: 34px;
    }
    .btn {
        height: 20px;
        font-size: .65em;
        margin: 2px;
        padding: 2px;
    }
    .factList {
        top: -249px;
        font-size: .60em;
    }
}

@media screen and (max-width: 580px) {
    .title {
        font-size: .91em;
    }
    .image {
        height: 165px;
    }
    .summary {
        height: 50px;
        font-size: .60em;
    }
    .btn {
        height: 25px;
        font-size: .80em;
        padding: 3px;
        margin: 3px;
    }
    .factList {
        font-size: .75em;
        top: -313px;
    }
}

