.timeline {
    display: flex;
    flex-flow: row wrap;
    width: 65%;
    margin: 0 auto;
}


@media screen and (max-width: 580px) {
    .timeline{
        flex-flow: column;
        width: 80%;
    }
}