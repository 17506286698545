.btnContainer {
    width: 100%;
    display: flex;
    margin-top: 50px;
    margin-bottom: 50px;
    flex-direction: row;
    justify-content: space-around;
}

.btn {
    border-radius: 4px;
    width: 100px;
    padding: 10px;
    /* background-color: #2B4162; */
    background-color: #Ef6F6C;
    text-decoration: none;
}

a:link, a:visited {
    /* color: #EDEEC0; */
    color: #F2C57C;
}

.btn a:hover {
    color: #F2C57C;
}