.image {
    width: 100%;
    height: 200px;
    cursor: pointer;
    /* box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 12px 0 rgba(0, 0, 0, 0.19); */
}

.blogLink:link, .blogLink:visited {
    text-decoration: none;
    /* color: #433E0E; */
    color: #426A5A;
    cursor: pointer;
}


.titleContainer{
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.title{
    padding: 3px;
    text-decoration: none;
    font-size: 14px;
    font-weight: bold;
}

.content{
    padding: 3px;
    font-size: 10px;
    text-transform: none;
    text-decoration: none;
    margin-left: 10%;
    width: 80%;
    height: 67px;
}

hr {
    width: 86%;
    border: 0;
    height: 0;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
}

.footContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin-top: -5px;
    font-size: 14px;
}

.comments {
    text-transform: none;
    font-size: 12px;
    margin-top: 3px;
}

.eye {
    margin-top:1px;
    padding-right: 3px;
}

.heart {
    color: #F40076;
    margin-top: 1px;
    padding-left: 1px;
    cursor: pointer;
}

@media screen and (max-width: 1086px) {
    .title {
        font-size: .89em;
    }
    .image {
        height: 155px;
    }
    .content {
        font-size: 9px;
        /* height: 40px; */

    }
    .comments {
        font-size: 12px;
    }
}

@media screen and (max-width: 850px) {
    .title {
        padding: 1px;
        font-size: .70em;
    }
    .image {
        height: 130px;
    }
    .content {
        padding: 0;
        font-size: .30em;
        height: 60px;
    }
    .comments {
        font-size: 11px;
    }
    .footContainer{
        font-size: 12px;
    }
}

@media screen and (max-width: 580px) {
    .title {
        font-size: .89em;
    }
    .image {
        height: 185px;
    }
    .content {
        margin-top: -15px;
        font-size: .55em;
        height: 59px;
    }
    .footContainer {
        font-size: 11px;
    }
    .comments {
        font-size: 10px;
    }
 
}
