
.btn {
    margin-top: 20px;
    margin-bottom: 50px;
    /* background-color: #2B4162; */
    background-color: #EF6F6C;
    /* color: #EDEEC0; */
    color: #F2C57C;
    padding: 12px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    width: 100%;
    font-family: 'Gingham';
    font-weight: 600;
    font-size: 15px;
}