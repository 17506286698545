.container {
    position: absolute;
    top: 0;
    left: 0;
    text-align: center;
    width: 100vw;
    height: 100vh;
    /* background-color: #7C9082; */
    background-color: #7FB685;
    overflow: auto;
}

@media screen and (max-width: 580px){
    .container{
        top: 9.9%;
    }
}