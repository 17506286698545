.timeItem {
    width: 100%;
    font-family: 'Gingham';
    height: 350px;
    margin-top: 10px;
    display: flex;
    flex-direction: column;
}

.itemCard {
    border-radius: 10px;
    border-top: 8px solid #6A041D;
    box-shadow: 0px 10px 30px -5px rgba(0, 0, 0, 0.3);
    transition: box-shadow 0.5s;
    will-change: transform;
    background: #f5f5f5;
    width:45%;                    
    height: 350px;
    cursor: pointer;
}

.itemCard:hover {
    box-shadow: 0px 30px 100px -10px rgba(0, 0, 0, 0.4);
}

.timeItem:nth-child(odd) .itemCard{
    border-top: 8px solid #153B50;
    align-self: flex-end;
}

.date {
    position: absolute;
    width: 102px;
    margin-top: 70px;
    border: 4px solid #f5f5f5;
    border-radius: 8px;
    padding: 10px;
    /* color: #433E0E; */
    color: #DDAE7E;
    /* background-color: #A7A284; */
    background-color: #426A5A;
    font-size: 25px;
}

.date:hover {
    /* background-color: #EDEEC0; */
    background-color: #EF6F6C;
}

.dateLine {
    border: 2px solid #f5f5f5;
    position: absolute;
    bottom: 20px;
    border-radius: 3px;
    
}

.timeItem:nth-child(odd) .date{
    align-self: flex-start;
    margin-left: calc(33% - 360px);
}

.timeItem:nth-child(even) .date{
    align-self: flex-end;
    margin-right: calc(33% - 360px);
}

.timeItem:nth-child(odd) .dateLine {
    left: 122px;
    width: 216px;
}

.timeItem:nth-child(even) .dateLine {
    right: 122px;
    width: 216px; 
}

.lineShapes {
    position: absolute;
    align-self: center;
}

.ball {
    /* background-color: #433E0E; */
    background-color: #426A5A;
    width: 25px;
    height: 25px;
    border-radius: 50%;
}

.line {
    height: 332px;
    border-left: 6px solid #433E0E;
    margin-top: 1px;
    margin-left: 38%;
}

.timeItem:last-child .line{
    height: 380px;

}

@media screen and (max-width: 1086px) {
    .itemCard {
        height: 308px;
    }
    .date {
        width: 75px;
        font-size: 18px;
    }
    .timeItem:nth-child(odd) .date{
        margin-left: calc(33% - 280px);
    }
    
    .timeItem:nth-child(even) .date{
        margin-right: calc(33% - 280px);
    }

    .timeItem:nth-child(odd) .dateLine {
        left: 97px;
        width: 164px;
    }
    .timeItem:nth-child(even) .dateLine {
        right: 97px;
        width: 164px; 
    }
    
}

@media screen and (max-width: 850px) {
    .itemCard {
        height: 263px;
    }

    .date {
        width: 60px;
        font-size: 14px;
    }
    .dateLine{
        bottom: 15px;
    }

    .timeItem:nth-child(odd) .date{
        margin-left: calc(33% - 200px);
    }
    
    .timeItem:nth-child(even) .date{
        margin-right: calc(33% - 200px);
    }

    .timeItem:nth-child(odd) .dateLine {
        left: 80px;
        width: 102px;
    }
    .timeItem:nth-child(even) .dateLine {
        right: 80px;
        width: 102px; 
    }
}

@media screen and (max-width: 580px) {
    .lineShapes {
        margin-top: 90px;
    }
    
    .timeItem {
        min-height: 380px;
    }

    .itemCard {
        height: 320px;
        align-self: center;
        width: 90%;
        z-index:4;
        display: block;
        margin-top: 150px;
    }

    .timeItem:nth-child(odd) .itemCard{
        align-self: center;
    }

    .timeItem:nth-child(even) .itemCard{
        align-self: center;
    }

    .date {
        width: 80px;
        font-size: 9px;
        z-index: 4;
    }
    .dateLine{
        bottom: 15px;
    }

    
    .timeItem:nth-child(odd) .date{
        margin-left: 0;
        align-self: center;
        margin: 110px;
    }
    
    .timeItem:first-child .date {
        margin: 50px;
    }
    .timeItem:nth-child(even) .date{
        margin-right: 0;
        align-self: center;
        margin: 100px;
    }

    .timeItem:nth-child(odd) .dateLine {
        display: none;
    }
    .timeItem:nth-child(even) .dateLine {
        display: none;
    }

}