.comment {
    display: flex;
    flex-direction: column;
    /* border: 1px solid #2B4162; */
    border: 1px solid #EF6F6C;
    border-radius: 8px;
    margin-top: 20px;
    padding: 15px;
    align-items: flex-start;
}

.header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width:100%;
}

.author {
    /* color: #2B4162; */
    color: #EF6F6C;

}

.date {
    /* color: #A7A284;  */
    color: #DDAE7E; 
}

.content {
    padding: 20px;
}
