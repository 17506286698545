.facts {
    margin: 0 auto;
    width: 85%;
    min-height: 350px;
}

.c {
    min-height: 350px;
    width: 80%;
    will-change: transform, opacity;
}

.title{
    font-size: 1em;
    font-weight: 800;
    /* color: #433E0E; */
    color: #426A5A;
    padding: 8px;
    width: 100%;
}

.fact{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
}

.row {
    padding: 5px;
    height: 40px;
    width: 100%;
}

.location{
    padding: 4px;
    width: 40%;
    display: inline-block;
}

.position{
    background-color: #EF6F6C;
    border-radius: 12px;
    width:40%;
    padding: 4px;
    display: inline-block;
}

.image {
    border-radius: 12px;
    height: 220px;
    width: 100%;
}

.details {
    padding: 10px;
    /* color: #433E0E; */
    color: #426A5A;
}

.details:hover {
    font-size:20px;
    /* background:#EDEEC0; */
    background:#F2C57C;
}

.resumeList {
    position: absolute;
    top: -340px;
    list-style-type: none;
    margin: 0;
    padding: 0;
}

.resumeLi {
    font: 200 12px/1.5 'Gingham', Helvetica, sans-serif;
    border-bottom: 1px solid #ccc;
}

.resumeLi:last-child {
    border: none;
}

.resumeLi:hover {
    font-size: 20px;
    /* background: #EDEEc0; */
    background: #F2C57C;
}

@media screen and (max-width: 1086px) {
    .facts {
        min-height: 308px;
    }

    .c {
        height: 308px;
    }

    .title {
        font-size: .91em;
    }

    .image {
        height: 155px;
    }

    .row {
        padding: 4px;
        height: 35px;
    }
    
    .location{
        padding: 3px;
        font-size: .78em;
    }
    
    .position{
        padding: 3px;
        font-size: .78em;
    }

    .resumeList {
        top: -270px;
    }

}

@media screen and (max-width: 850px) {
    .facts {
        min-height: 263px;
    }

    .c {
        height: 263px;
    }

    .title {
        font-size: .82em;
    }

    .image {
        height: 135px;
    }

    .row {
        padding: 3px;
        height: 30px;
    }
    
    .location{
        padding: 3px;
        font-size: .65em;
    }
    
    .position{
        padding: 3px;
        font-size: .65em;
    }

    .resumeList {
        top: -236px;
    }

    .resumeLi {
        font-size: .6em;
    }
}

@media screen and (max-width: 580px) {
    .facts {
        min-height: 320px;
    }

    .c {
        height: 320px;
    }

    .title {
        font-size: .91em;
    }

    .image {
        height: 165px;
    }

    .row {
        padding: 4px;
        height: 40px;
    }
    
    .location{
        padding: 4px;
        font-size: .78em;
    }
    
    .position{
        padding: 4px;
        font-size: .78em;
    }

    .resumeList {
        top: -280px;
    }

    .resumeLi {
        font-size: .7em;
    }

}